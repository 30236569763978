.top-bar {
  width: 100%;
  height: 25px;
  background-color: #231d29;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
}
