.container {
  width: 100%;
  height: 100%;
  background-color: #000d;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999;
}

.screens-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}