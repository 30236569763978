.screen-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #2c2c2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  position: absolute;
  left: calc(50% - 573px / 2 + 0.5px);
  top: 10%;

  font-family: "Inknut Antiqua";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 103px;
  text-transform: uppercase;

  color: #ffffff;
}

.tagline {
  /* If the function of the works we research and design is to provide playful experiences, then what might it mean to communicate playful form in how we disseminate our work? */

  position: absolute;
  width: 573px;
  height: 52px;
  left: calc(50% - 573px / 2 + 0.5px);
  top: 30%;
  text-align: center;
  align-self: center;

  font-family: "Inknut Antiqua";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 26px;
  display: flex;
  text-align: center;

  color: #ffffff;
}

.button-container {
  /* Rectangle 20 */

  display: flex;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 64px;
  align-self: center;
  justify-self: center;
  border: 3px solid #918f8f;
  border-radius: 10px;
  margin-top: 20px;
}

.button-container-focused {
  /* Rectangle 20 */

  display: flex;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 64px;
  align-self: center;
  justify-self: center;
  border: 3px solid #918f8f;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #918f8f;
}

.button-label {
  font-family: "Inknut Antiqua";
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
}

.button-label-focused {
  font-family: "Inknut Antiqua";
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  text-transform: capitalize;

  color: #000;
}
