.icon-sprite {
  width: 50px;
  height: 50px;
  vertical-align: center;
}

.icon-label {
  font-size: 12px;
  width: 110px;
  text-align: center;
  text-wrap: balance;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: rgb(244, 244, 244);
  text-shadow: 0px 2px 6px rgb(69, 69, 69);
}

.show-borders {
  border-color: black;
  border-style: solid;
  border-width: 1px;
}
