* { 
  /* defined to be none so we can use the custom cursor */
  cursor: none;
}

body {
  margin: 0;
  line-height: normal;
  overflow: hidden;
}
