.locked-desktop {
  display: flex;
  width: 340px;
  height: 240px;
  border-width: 5px;
  border-style: solid;
  border-color: #656565;
  border-radius: 15px;
  z-index: 30;
  align-items: center;
  justify-content: center;
  background-color: #fff2;
}

.desktop {
  width: 350px;
  height: 250px;
  background-size: cover;
  object-fit: cover;
  overflow: auto;
  border-radius: 15px;
  z-index: 30;
  opacity: 80%;
}
