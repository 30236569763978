.reflection-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8vh;
  width: 30vw;
  top: -8vh;
  left: 35vw;
  align-self: center;
  justify-self: center;
  border-radius: 15px;
  background: rgba(50, 50, 50, 0.7);
  backdrop-filter: blur(4.8px);
  -webkit-backdrop-filter: blur(4.8px);
  z-index: 100;
}

.body-text {
  font-family: "Inknut Antiqua";
  font-style: normal;
  font-size: 12px;
  font-weight: 100;
  color: #ffffff;
  text-align: center;
  padding: 0px 10px 0px 10px;
}
