.taskbar-container {
  display: flex;
  position: relative;
  height: 5vh;
  width: 100vw;
  background-color: #05293b;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 0%;
}

.taskbar-leftside {
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.taskbar-rightside {
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

.date-time {
  color: white;
  font-size: 12px;
  font-family: sans-serif;
  text-align: center;
}

.search-bar-container {
  display: flex;
  flex-direction: row;
  width: 20vw;
  height: 5vh;
  /* height to be the same as the taskbar */
  background-color: white;
  margin: 0px 20px 0px 20px;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding-left: 10px;
}

.search-bar-text { 
  font: 15px;
  font-family: sans-serif;
  font-weight: 200;
  margin: 0px 0px 0px 10px;
}